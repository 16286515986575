<template>
  <div>
    <grid
      item-min-width="310px"
      class="align-center"
    >
      <translations
        :label="$t('t.Name')"
        :is-new="true"
        :rules="nameRules"
        :translations.sync="computedName"
        :dense="dense"
      />
      <div class="d-flex flex-row gap">
        <filter-params
          v-model="computedFilter"
          :auto-filter-type="viewType"
          consolidable
        />
        <filter-params
          class="ml-2"
          v-model="computedFilterPrevious"
          :auto-filter-type="viewType"
          :label="$t('t.FilterPrevious')"
          consolidable
        />
      </div>
    </grid>
    <params
      :document.sync="computedParams"
      :action-type.sync="computedActionType"
      :view-type="viewType"
      :dense="dense"
      ref="params"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    FilterParams: () => import('@/pages/cameleon/columns/paramsType/filter-params/filter-params'),
    Grid: () => import('@/components/grid'),
    Params: () => import('./params'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    computedActionType: {
      get () { return this.dataActionType },
      set (v) {
        this.dataActionType = v
        this.$emit('update:action-type', this.dataActionType)
      }
    },
    computedFilter: {
      get () {
        return this.dataAction?.filter
      },
      set (v) {
        this.dataAction.filter = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedFilterPrevious: {
      get () {
        return this.dataAction?.filterPrevious
      },
      set (v) {
        this.dataAction.filterPrevious = v
        this.dataDocumentEmit()
      }
    },
    computedParams: {
      get () {
        return this.dataAction?.params
      },
      set (v) {
        this.dataAction.params = v
        this.dataDocumentEmit()
      }
    },
    computedName: {
      get () { return this.dataAction?.name || {} },
      set (v) {
        this.dataAction.name = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    }
  },
  data () {
    return {
      dataAction: {},
      dataActionType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataAction)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataAction))
      }
    }
  },
  props: {
    actionType: String,
    document: Object,
    viewType: String,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataAction = v ? this.lodash.cloneDeep(v) : null
        this.dataDocumentEmit()
      }
    },
    actionType: {
      immediate: true,
      handler (v) {
        if (v) {
          this.dataActionType = v
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%
</style>
